@import url(https://fonts.googleapis.com/css2?family=Lora&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora&display=swap);
#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
  position: absolute;
  background-color: white;
  border-radius: 1rem;
}

.signup_background_image,
.login_background_image {
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    margin: 0;
    background-attachment: fixed;
}

.signup_background_image {
    background-image: url(/static/media/bread.b57e9efb.jpg);
}

.login_background_image {
    background-image: url(/static/media/grapes.db7d4836.jpg);
}


.auth_form_container{
    width: 50%;
    max-width: 800px;
    background-color: #ffffffc5;
    border-radius: 15px;
    padding: 15px;
    position: relative;
    top: 25px;
    left: 25px;
}

.auth_form_title {
    margin: 15px;
    font-family: 'Lora', serif;
}

.auth_form {
    display: flex;
    flex-direction: column;
}


/* Inputs */
.auth_form_input_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 95%;
    margin: 15px;
}

.auth_form_constraints {
    margin: 0 15px;
}

.auth_form_input_label{
    font-family: 'Lora', serif;
    font-size: 1.25rem;
}

.auth_form_input {
    margin-top: 5px;
    font-size: 1.25rem;
    padding: .5rem;
}

.auth_form_input::-webkit-input-placeholder {
    font-style: italic;
    font-family: 'Lora', serif;
}

.auth_form_input::placeholder {
    font-style: italic;
    font-family: 'Lora', serif;
}


/* Buttons */
.auth_form_button_div {
    display: flex;
    justify-content: space-between;
    margin-right: 25px;

}

.auth_form_button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: coral;
    border: none;
    border-radius: 1rem;
    padding: 0.75rem;
    color: #fff;
    cursor: pointer;
    margin: 15px;
    transition: 250ms ease;
}

.auth_form_button:hover {
    background-color: black;
}


/* Bottom */
.form_error {
    color: white;
    font-size: .75rem;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    border-radius: 1em;
    background-color: rgba(255, 0, 0, 0.723);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: .5rem;
    margin: .5rem 0;
}



.auth_form_signup_link_div > a {
    margin-left: 15px;
    text-decoration: none;
    color: black
}

.auth_form_signup_link {
    -webkit-text-decoration: underline coral solid;
            text-decoration: underline coral solid;
    text-underline-position: under;
    text-underline-offset: .1em;
}

.auth_form_signup_link:hover {
    -webkit-text-decoration: underline 4px coral;
            text-decoration: underline 4px coral;
    font-weight: bold;
}

.navbar_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 3rem;
}

.navbar_div a,
.navbar_div a:visited,
.navbar_div a:active {
  text-decoration: none;
  color: black;
  cursor: pointer;
  font-size: 1.25em;
  font-family: "Lora", serif;
}

.profile_menu_button {
  color: coral;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 2em;;
  transition: 250ms ease;
}

.profile_menu_button:hover {
  color: black;
}

.navbar_user_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: bold;
  width: 15%;
}

.navbar_user_container a:hover {
  -webkit-text-decoration: underline 4px solid coral;
          text-decoration: underline 4px solid coral;
  text-underline-position: under;
  text-underline-offset: .1em;
}

.navbar_logged_out_elements {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navbar_logo {
  font-size: 3rem;
  font-family: 'Great Vibes', cursive;
}

.navbar_search_div {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.search_form_input {
  margin-right: 5px;
  height: 1.5em;
  padding: 1em;
}

.navbar_section {
  width: 15%;
}


/*From Starter Code*/
.profile-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  top: 65px;
  left: 1em;
  min-width: 200px;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  border: 1px solid #aaa;
  background-color: #fef9f8;
  border-radius: .5em;
  padding: 1em;
  z-index: 1;
}

.user_drop_down_div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1em 0;
  align-items: center;
}

.user_drop_down_button {
  font-size: 1em;
  border: none;
  border-radius: 5px;
  background-color: coral;
  padding: .75rem;
  color: white;
  cursor: pointer;
  transition: 250ms ease;
}

.user_drop_down_button:hover {
  background-color: black;
}

.hidden {
  display: none;
}

/* TODO: Add styling for recipe tiles */
.recipe_tiles_div {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18rem, auto));
    grid-column-gap: .75em;
    grid-row-gap: 3rem;
    padding: 0 3rem;
}

.recipe_tile {
    display: flex;
    flex-direction: column;
    margin: 15px;
    max-width: 250px;
    border-radius: 0 0 15px 15px;
    border: 1px solid #ccc;
}

.recipe_tile_details_link {
    cursor: pointer;
}

.recipe_tile_details_link:hover .recipe_tile_title {
    -webkit-text-decoration: underline 4px coral;
            text-decoration: underline 4px coral;
    text-underline-position: under;
    text-underline-offset: .1em;
}

.recipe_tile_image {
    -webkit-filter: contrast(85%);
            filter: contrast(85%);
    transition: 250ms ease;
}

.recipe_tile_details_link:hover .recipe_tile_image {
    -webkit-filter: contrast(100%);
            filter: contrast(100%);
}


.recipe_tile_image .favorited_icon {
    color: coral;
    position: relative;
    top: -290px;
    left: 10px;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
    transition: 200ms ease;
}

.recipe_tile_image .favorited_icon:hover {
    font-size: 1.5rem;
}

.recipe_tile_image {
    width: 100%;
    height: 300px;
    display: block;
    object-fit: cover;
}

.recipe_tile_title {
    font-weight: bold;
    font-size: 1.5rem;
    padding: 1rem;
    font-family: 'Lora', serif;
}

.recipe_tile_description,
.recipe_tile_author {
    font-size: 1rem;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    padding: 0 1rem;
    margin-top: 1rem;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

.recipe_tile_author {
    font-style: italic;
}

.recipe_tile_author > a {
    -webkit-text-decoration: underline coral;
            text-decoration: underline coral;
    text-underline-offset: .25rem;
    font-family: 'Lora', serif;;
    font-weight: bold;
}

.recipe_tile_author > a,
.recipe_tile_author > a:visited {
    color: black
}


.recipe_tile_author > a:hover {
    text-decoration-thickness: 4px;
    font-weight: bold;
}

.recipe_tile_tags {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    align-content: flex-end;
    justify-self: flex-end;
    flex-wrap: wrap;
    padding: 5px;
    height: 100%;
    margin-top: 1em;
}

.recipe_tile_tags > a {
    margin: .25em;
    padding: .5em;
    font-size: 75%;
    max-width: 95%;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: coral;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #fff;
    transition: 350ms ease;
    border-radius: .25em;
}

.recipe_tile_tags > a:hover {
    background-color: black;
}

.footer_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 1rem;
    padding: 1rem;
    font-family: "Lora", serif;
}

.footer_wrapper a {
    text-decoration: none;
    color: black;
    transition: 150ms ease;
}

.footer_wrapper a:visited {
    color: black;
}

.footer_wrapper a:hover {
    color: coral;
}

.footer_upper_content {
    font-size: 1.25rem;
    line-height: 1.75rem;
    text-align: center;
    font-weight: bold;
}

.footer_upper_content a {
    text-decoration: underline;
    font-style: italic;
}

.footer_lower_content {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    margin-bottom: 1rem;
    justify-content: space-around;
}

.footer_lower_content i {
    font-size: 1.75rem;
}

.footer_section {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}

.section_header {
    font-size: 1.25rem;
    font-weight: bold;
    font-family: "Lora", serif;
    text-decoration: underline;
    text-underline-offset: .5rem;
    margin-bottom: .5rem;
    width: 100%;
    text-align: center;
}

.section_content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.other_project_link {
    font-size: 1rem;
    align-self: center;
    font-weight: bold;
}

.section_content.other_project {
    justify-content: space-between;
}

.section_content.other_project a {
    margin-right: .5rem;
}

.delete_confirmation_modal_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  width: 400px;
}

.delete_confirmation_modal_title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  font-family: "Lora", serif;
}

.delete_confirmation_modal_info {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

.delete_confirmation_modal_errors_list {
  list-style-type: none;
  color: red;
  margin: 0;
  padding: 0;
  margin-bottom: 1.5rem;
}

.delete_confirmation_modal_errors_list li {
  margin-bottom: 0.5rem;
}

.delete_confirmation_modal_button_container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.delete_confirmation_modal_cancel_button,
.delete_confirmation_modal_confirm_button {
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  border: none;
  border-radius: 1rem;
  cursor: pointer;
  font-weight: bold;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  transition: 250ms ease;
}

.delete_confirmation_modal_cancel_button {
  background-color: #fef9f8;
  color: #AAA;
  border: 2px solid #AAA;
}

.delete_confirmation_modal_confirm_button {
  background-color: coral;
  color: white;
}


.delete_confirmation_modal_confirm_button:hover,
.delete_confirmation_modal_cancel_button:hover {
  background-color: black;
  color: white;
}

.delete_confirmation_modal_cancel_button:hover {
  border: 2px solid black;
}

.add_review_modal {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    padding-top: 0px;
    font-family: sans-serif;
    align-items: center;
    width: 30rem;
    border-radius: 1rem;
}

.add_review_modal_title {
    font-size: 2rem;
    font-family: 'Lora', serif;
}

.add_review_modal_review_input {
    resize: none;
    width: 100%;
    height: 10vh;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: .5rem;
    font-size: 1.25rem;
}

.add_review_modal_star_rating_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px;
}

.add_review_modal_star_rating_label {
    margin: 0 5px;
    font-size: 1.25rem;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

.add_review_modal_star_radio_input {
    display: none;
}

.review_star_input {
    cursor: pointer;
    font-size: 1.25rem;
}

.filled {
    color: coral;
}

.not-filled {
    color: #CCC;
}

.post_review_button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.25rem;
    padding: 15px;
    border-radius: 1rem;
    transition: 250ms ease;
    border: none;
    cursor: pointer;
}

.post_review_button.enabled {
    background-color: coral;
    color: #FFFFFF;
}

.post_review_button.enabled:hover {
    background-color: black;
}

.post_review_button.disabled {
    border: none;
    background-color: #CCC;
    color: white;
}

.recipe_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 33%;
    min-width: 600px;
    margin: 0 auto;
}

.recipe_container  * {
    margin: 15px 0px;
    max-width: 100%;
}

.recipe_title_and_buttons_div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.single_recipe_title {
    font-size: 3rem;
    font-weight: bold;
    max-width: 75%;
    font-family: 'Lora', serif;
}

.recipe_details_buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}

.recipe_details_buttons > * {
    border: none;
    font-size: 2rem;
    padding: .25rem;
    background-color: transparent;
    text-decoration: none;
    color: black;
    cursor: pointer;
    transition: 250ms ease;
}

.recipe_details_buttons #favorite_icon_empty,
.recipe_details_buttons #favorite_icon_solid {
    color: coral;
}

.recipe_details_buttons > *:hover {
    color: coral;
    font-size: 2.25rem;
}


.single_recipe_tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.single_recipe_tags > a {
    margin: .25em;
    padding: .5em;
    background-color: coral;
    border-radius: .25em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #fff;
    transition: 350ms ease;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

.single_recipe_tags > a:hover {
    background-color: black;
}


/* Recipe Image */
.single_recipe_image_div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.recipe_detail_image {
    height: 600px;
    width: 600px;
    object-fit: cover;
    border-radius: 1rem;
}

/************************ Recipe Details ****************************/

.recipe_details_div {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
}

.recipe_details_title_author_div,
.recipe_details_reviews_summary_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Lora', serif;
    max-width: 75%;
}

.recipe_num_reviews {
    margin-top: 0;
    font-size: 1.25rem;
}

.recipe_details_title {
    font-size: 1.75rem;
    font-weight: bold;
}

.recipe_details_author,
.recipe_details_estimated_time {
    font-size: 1.25rem;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;

}

.recipe_details_author > a,
.recipe_details_time {
    color: black;
    text-decoration: none;
    font-style: italic;
    font-weight: bold;
    transition: 250ms ease;
    font-family: 'Lora', serif;
}

.recipe_details_author > a:hover {
    color: coral;
}

.recipe_details_reviews_summary_div {
    font-size: 1.5rem;
    align-items: flex-end;
}

.recipe_avg_rating {
    margin: 0;
}

/***************** Description *******************/
.single_recipe_description {
    font-size: 1.25rem;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
}


.recipe_instruction_title,
.recipe_ingredients_title,
.review_title {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 'Lora', serif;
    text-decoration: underline;
}

.recipe_method_step_number,
.recipe_ingredient {
    font-size: 1.25rem;
    font-weight: bold;
    font-family: 'Lora', serif;
}

.recipe_method_details {
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 2rem;
}

.recipe_ingredient {
    list-style: none;
}

.ingredient_checkbox {
    margin-right: 1rem;
    cursor: pointer;
    accent-color: coral;
}



/************************** Steps ***************************/
.recipe_method_div {
    margin-left: 1rem;
}

.recipe_method_image_and_details_div {
    display: flex;
    flex-direction: row;
}

.recipe_method_image {
    max-height: 100%;
    width: 50%;
    border-radius: 1rem;
    margin-right: 1rem;
}

.recipe_method_details {
    font-size: 1.25rem;
    padding: 1rem;
}


/***************************** Reviews ******************************/
.reviews_div {
    display: flex;
    flex-direction: column;
    min-height: 25em;
}

.review_heading_div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0;
}

.review_average_rating,
.review_heading_left_div {
    font-size: 1.5rem;
}

.review_heading_right_div {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.review_heading_right_div *{
    margin: 0;
    padding: 0
}

.review_author_rating_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}

.add_review_button_div {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0;
}

.add_review_button_div *{
    background-color: coral;
    border: none;
    border-radius: 1rem;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: 'Lora', serif;
    padding: .75rem;
    cursor: pointer;
    transition: 250ms ease;
    margin-top: 0;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

.add_review_button_div *:hover {
    background-color: black;
}

/* Individual Review */
.review_wrapper * {
    word-break: break-all;
}

.review_wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid #999;
    border-radius: 1rem;
    padding: .5rem 1.5rem;
    background-color: #fef9f8;
}

.review_section,
.review_user_and_rating_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.25rem;
}

.review_timestamp,
.review_details {
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

.review_timestamp {
    font-size: 1rem;
}

.review_details {
    line-height: 1.5rem;
}

.review_username {
    margin-right: 5px;
    font-family: 'Lora', serif;
    font-weight: bold
}

.review_section {
    justify-content: space-between;
    align-items: center;
}

.review_section * {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
}

.star_rating,
.review_modal_buttons > *:hover {
    color: coral;
}

.review_modal_buttons_div *{
    background-color: transparent;
    border: none;
    font-size: 1.25rem;
    cursor: pointer;
    transition: 200ms ease;
    margin: 0 .15rem;
}

.review_modal_buttons_div *:hover {
    color: coral;
}

.review_section.review_top_div {
    margin-bottom: 0;
}

.review_section.review_modal_buttons_div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 0;
}

.review_section.review_details {
    margin-bottom: 0;
}

.recipe_form_background_image {
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(/static/media/bread.b57e9efb.jpg);
    background-attachment: fixed;
    height: 100%;
    min-height: 100vh;
    width: 100%;
    padding: 3rem 0;
}

.recipe_form {
    display: flex;
    flex-direction: column;
    width: 25%;
    min-width: 700px;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    margin: 0 auto;
    background-color: #fef9f8db;
    padding: 1.75rem;
    border-radius: 1.5rem;
}

.recipe_form_input_constraints {
    font-size: 14px;
    font-weight: bold;
    margin: .5rem 0;
    font-family: "Lora", serif;
}

.recipe_form_input_recommendations {
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 1.5rem;
    margin-top: 0;
}

.recipe_form textarea {
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    height: 200px;
}

.recipe_form_textarea_num_chars {
    font-family: "Lora", serif;
    margin: .5rem;
}

.num_chars_remaining {
    font-weight: bold;
}

.recipe_form_label {
    font-size: 1.25rem;
    font-family: "Lora", serif;
    margin-bottom: .75rem;
    font-weight: bold;
}

.recipe_form_sublabel {
    font-size: 1rem;
    font-family: "Lora", serif;
    font-weight: bold;
}

.recipe_form_input {
    padding: .5rem;
}

.recipe_form_input::-webkit-input-placeholder {
    font-style: italic;
}

.recipe_form_input::placeholder,
.default_option_ingredient_unit {
    font-style: italic;
}

.recipe_form_input_div,
.method_div {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.recipe_form_time_to_make {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.recipe_form_title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    font-family: "Lora", serif;
}

.recipe_form_description_input {
    height: 6rem;
    resize: none;
}

.recipe_preview {
    height: 250px;
    width: 250px;
    object-fit: scale-down;
    border: 1px solid #666;
    border-radius: 1em;
    background-color: rgba(245, 245, 220, 0.428);
    font-family: "Lora", serif;
}

.empty_preview {
    display: flex;
    align-items: center;
    justify-content: center;
}

input[type="file"] {
    display: none;
}

.recipe_form_preview_image_input {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.select_image_button {
    border: none;
    font-size: .75rem;
    cursor: pointer;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: coral;
    color: white;
    padding: .5rem;
    margin: .5rem 0;
    border-radius: .25rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    transition: 250ms ease;
}

/************************************************************************************ Ingredients **************************************************************************/

.ingredients_inputs {
    display: inline-flex;
    justify-content: space-between;
    margin: 5px 0px;
}

.ingredient_input {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.add_ingredient_button,
.add_method_button,
.remove_method_button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-self: flex-end;
    margin: 10px 0px;
}

.add_ingredient_button,
.add_method_button {
    border: none;
    background-color: coral;
    border-radius: .25em;
    color: white;
    cursor: pointer;
    padding: .5rem;
    transition: 300ms ease;
    font-size: .75rem;
}

.add_ingredient_button:hover,
.add_method_button:hover,
.select_image_button:hover {
    background-color: black;
}

/************************************************************************************ Method/Ingredient Buttons **************************************************************************/
.remove_method_button,
.remove_ingredient_button {
    background-color: transparent;
    border: none;
    color: black;
    cursor: pointer;
    transition: 250ms ease;
    font-size: 1rem;
}

.remove_method_button:hover,
.remove_ingredient_button:hover {
    color: coral;
}

/************************************************************************************ Tags **************************************************************************/
.recipe_form_display_tag_div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.tag {
    margin: .5rem;
    padding: .25rem;
    background-color: coral;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.tag_name {
    margin-right: .25rem;
    font-size: 12px;
}

.tag_name,
.remove_tag_button {
    color: #fff;
}

.remove_tag_button {
    font-size: 8px;;
    justify-self: flex-start;
    cursor: pointer;
    transition: 150ms ease;
}

.remove_tag_button:hover {
    font-size: .75rem;
    font-weight: bold;
    color: black;
}


/************************************************************************************ Bottom **************************************************************************/
.recipe_form_submit_button {
    width: 50%;
    padding: 10px 0px;
    margin: 1.5rem;
    align-self: center;
    background-color: coral;
    color: #fff;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    transition: 250ms ease;
    font-size: 1.25rem;
}

.recipe_form_submit_button:hover {
    background-color: black;
}

.recipe_form_legend {
    text-align: right;
    font-family: "Lora", serif;
    font-weight: bold;
}

.required_input {
    color: red;
}

.user_page_container {
    display: flex;
    flex-direction: column;
}


.user_page_title_div {
    display: flex;
    flex-direction: row;
}

.user_page_title,
.user_page_icon {
    font-size: 3em;
    font-weight: bold;
    margin: 0 .5em;
}

.tag_recipe_container {
    display: flex;
    flex-direction: column;
}

.recipe_page_tile {
    font-size: 3em;
    font-weight: bold;
    margin-left: 3em;
    margin-bottom: .5em;
    font-family: 'Lora', serif;
}

/* TODO Add site wide styles */


* {
    box-sizing: border-box;
    overflow-wrap: break-word;
}

html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

