.recipe_form_background_image {
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../assets/images/bread.jpg");
    background-attachment: fixed;
    height: 100%;
    min-height: 100vh;
    width: 100%;
    padding: 3rem 0;
}

.recipe_form {
    display: flex;
    flex-direction: column;
    width: 25%;
    min-width: 700px;
    min-height: fit-content;
    margin: 0 auto;
    background-color: #fef9f8db;
    padding: 1.75rem;
    border-radius: 1.5rem;
}

.recipe_form_input_constraints {
    font-size: 14px;
    font-weight: bold;
    margin: .5rem 0;
    font-family: "Lora", serif;
}

.recipe_form_input_recommendations {
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 1.5rem;
    margin-top: 0;
}

.recipe_form textarea {
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    height: 200px;
}

.recipe_form_textarea_num_chars {
    font-family: "Lora", serif;
    margin: .5rem;
}

.num_chars_remaining {
    font-weight: bold;
}

.recipe_form_label {
    font-size: 1.25rem;
    font-family: "Lora", serif;
    margin-bottom: .75rem;
    font-weight: bold;
}

.recipe_form_sublabel {
    font-size: 1rem;
    font-family: "Lora", serif;
    font-weight: bold;
}

.recipe_form_input {
    padding: .5rem;
}

.recipe_form_input::placeholder,
.default_option_ingredient_unit {
    font-style: italic;
}

.recipe_form_input_div,
.method_div {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.recipe_form_time_to_make {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.recipe_form_title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    font-family: "Lora", serif;
}

.recipe_form_description_input {
    height: 6rem;
    resize: none;
}

.recipe_preview {
    height: 250px;
    width: 250px;
    object-fit: scale-down;
    border: 1px solid #666;
    border-radius: 1em;
    background-color: rgba(245, 245, 220, 0.428);
    font-family: "Lora", serif;
}

.empty_preview {
    display: flex;
    align-items: center;
    justify-content: center;
}

input[type="file"] {
    display: none;
}

.recipe_form_preview_image_input {
    max-width: fit-content;
}

.select_image_button {
    border: none;
    font-size: .75rem;
    cursor: pointer;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: coral;
    color: white;
    padding: .5rem;
    margin: .5rem 0;
    border-radius: .25rem;
    width: fit-content;
    transition: 250ms ease;
}

/************************************************************************************ Ingredients **************************************************************************/

.ingredients_inputs {
    display: inline-flex;
    justify-content: space-between;
    margin: 5px 0px;
}

.ingredient_input {
    width: fit-content;
}

.add_ingredient_button,
.add_method_button,
.remove_method_button {
    width: fit-content;
    align-self: flex-end;
    margin: 10px 0px;
}

.add_ingredient_button,
.add_method_button {
    border: none;
    background-color: coral;
    border-radius: .25em;
    color: white;
    cursor: pointer;
    padding: .5rem;
    transition: 300ms ease;
    font-size: .75rem;
}

.add_ingredient_button:hover,
.add_method_button:hover,
.select_image_button:hover {
    background-color: black;
}

/************************************************************************************ Method/Ingredient Buttons **************************************************************************/
.remove_method_button,
.remove_ingredient_button {
    background-color: transparent;
    border: none;
    color: black;
    cursor: pointer;
    transition: 250ms ease;
    font-size: 1rem;
}

.remove_method_button:hover,
.remove_ingredient_button:hover {
    color: coral;
}

/************************************************************************************ Tags **************************************************************************/
.recipe_form_display_tag_div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.tag {
    margin: .5rem;
    padding: .25rem;
    background-color: coral;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.tag_name {
    margin-right: .25rem;
    font-size: 12px;
}

.tag_name,
.remove_tag_button {
    color: #fff;
}

.remove_tag_button {
    font-size: 8px;;
    justify-self: flex-start;
    cursor: pointer;
    transition: 150ms ease;
}

.remove_tag_button:hover {
    font-size: .75rem;
    font-weight: bold;
    color: black;
}


/************************************************************************************ Bottom **************************************************************************/
.recipe_form_submit_button {
    width: 50%;
    padding: 10px 0px;
    margin: 1.5rem;
    align-self: center;
    background-color: coral;
    color: #fff;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    transition: 250ms ease;
    font-size: 1.25rem;
}

.recipe_form_submit_button:hover {
    background-color: black;
}

.recipe_form_legend {
    text-align: right;
    font-family: "Lora", serif;
    font-weight: bold;
}

.required_input {
    color: red;
}
