/* TODO Add site wide styles */
@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');


* {
    box-sizing: border-box;
    overflow-wrap: break-word;
}

html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}
