.delete_confirmation_modal_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  width: 400px;
}

.delete_confirmation_modal_title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  font-family: "Lora", serif;
}

.delete_confirmation_modal_info {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

.delete_confirmation_modal_errors_list {
  list-style-type: none;
  color: red;
  margin: 0;
  padding: 0;
  margin-bottom: 1.5rem;
}

.delete_confirmation_modal_errors_list li {
  margin-bottom: 0.5rem;
}

.delete_confirmation_modal_button_container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.delete_confirmation_modal_cancel_button,
.delete_confirmation_modal_confirm_button {
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  border: none;
  border-radius: 1rem;
  cursor: pointer;
  font-weight: bold;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  transition: 250ms ease;
}

.delete_confirmation_modal_cancel_button {
  background-color: #fef9f8;
  color: #AAA;
  border: 2px solid #AAA;
}

.delete_confirmation_modal_confirm_button {
  background-color: coral;
  color: white;
}


.delete_confirmation_modal_confirm_button:hover,
.delete_confirmation_modal_cancel_button:hover {
  background-color: black;
  color: white;
}

.delete_confirmation_modal_cancel_button:hover {
  border: 2px solid black;
}
