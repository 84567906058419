.signup_background_image,
.login_background_image {
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    margin: 0;
    background-attachment: fixed;
}

.signup_background_image {
    background-image: url("../../assets/images/bread.jpg");
}

.login_background_image {
    background-image: url("../../assets/images/grapes.jpg");
}


.auth_form_container{
    width: 50%;
    max-width: 800px;
    background-color: #ffffffc5;
    border-radius: 15px;
    padding: 15px;
    position: relative;
    top: 25px;
    left: 25px;
}

.auth_form_title {
    margin: 15px;
    font-family: 'Lora', serif;
}

.auth_form {
    display: flex;
    flex-direction: column;
}


/* Inputs */
.auth_form_input_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 95%;
    margin: 15px;
}

.auth_form_constraints {
    margin: 0 15px;
}

.auth_form_input_label{
    font-family: 'Lora', serif;
    font-size: 1.25rem;
}

.auth_form_input {
    margin-top: 5px;
    font-size: 1.25rem;
    padding: .5rem;
}

.auth_form_input::placeholder {
    font-style: italic;
    font-family: 'Lora', serif;
}


/* Buttons */
.auth_form_button_div {
    display: flex;
    justify-content: space-between;
    margin-right: 25px;

}

.auth_form_button {
    width: fit-content;
    background-color: coral;
    border: none;
    border-radius: 1rem;
    padding: 0.75rem;
    color: #fff;
    cursor: pointer;
    margin: 15px;
    transition: 250ms ease;
}

.auth_form_button:hover {
    background-color: black;
}


/* Bottom */
.form_error {
    color: white;
    font-size: .75rem;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    border-radius: 1em;
    background-color: rgba(255, 0, 0, 0.723);
    width: fit-content;
    padding: .5rem;
    margin: .5rem 0;
}



.auth_form_signup_link_div > a {
    margin-left: 15px;
    text-decoration: none;
    color: black
}

.auth_form_signup_link {
    text-decoration: underline coral solid;
    text-underline-position: under;
    text-underline-offset: .1em;
}

.auth_form_signup_link:hover {
    text-decoration: underline 4px coral;
    font-weight: bold;
}
