.add_review_modal {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    padding-top: 0px;
    font-family: sans-serif;
    align-items: center;
    width: 30rem;
    border-radius: 1rem;
}

.add_review_modal_title {
    font-size: 2rem;
    font-family: 'Lora', serif;
}

.add_review_modal_review_input {
    resize: none;
    width: 100%;
    height: 10vh;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: .5rem;
    font-size: 1.25rem;
}

.add_review_modal_star_rating_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px;
}

.add_review_modal_star_rating_label {
    margin: 0 5px;
    font-size: 1.25rem;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

.add_review_modal_star_radio_input {
    display: none;
}

.review_star_input {
    cursor: pointer;
    font-size: 1.25rem;
}

.filled {
    color: coral;
}

.not-filled {
    color: #CCC;
}

.post_review_button {
    width: fit-content;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.25rem;
    padding: 15px;
    border-radius: 1rem;
    transition: 250ms ease;
    border: none;
    cursor: pointer;
}

.post_review_button.enabled {
    background-color: coral;
    color: #FFFFFF;
}

.post_review_button.enabled:hover {
    background-color: black;
}

.post_review_button.disabled {
    border: none;
    background-color: #CCC;
    color: white;
}
