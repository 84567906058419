.navbar_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 3rem;
}

.navbar_div a,
.navbar_div a:visited,
.navbar_div a:active {
  text-decoration: none;
  color: black;
  cursor: pointer;
  font-size: 1.25em;
  font-family: "Lora", serif;
}

.profile_menu_button {
  color: coral;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 2em;;
  transition: 250ms ease;
}

.profile_menu_button:hover {
  color: black;
}

.navbar_user_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: bold;
  width: 15%;
}

.navbar_user_container a:hover {
  text-decoration: underline 4px solid coral;
  text-underline-position: under;
  text-underline-offset: .1em;
}

.navbar_logged_out_elements {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navbar_logo {
  font-size: 3rem;
  font-family: 'Great Vibes', cursive;
}

.navbar_search_div {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  min-width: fit-content;
}

.search_form_input {
  margin-right: 5px;
  height: 1.5em;
  padding: 1em;
}

.navbar_section {
  width: 15%;
}


/*From Starter Code*/
.profile-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  top: 65px;
  left: 1em;
  min-width: 200px;
  min-height: fit-content;
  border: 1px solid #aaa;
  background-color: #fef9f8;
  border-radius: .5em;
  padding: 1em;
  z-index: 1;
}

.user_drop_down_div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1em 0;
  align-items: center;
}

.user_drop_down_button {
  font-size: 1em;
  border: none;
  border-radius: 5px;
  background-color: coral;
  padding: .75rem;
  color: white;
  cursor: pointer;
  transition: 250ms ease;
}

.user_drop_down_button:hover {
  background-color: black;
}

.hidden {
  display: none;
}
