/* TODO: Add styling for recipe tiles */
.recipe_tiles_div {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18rem, auto));
    grid-column-gap: .75em;
    grid-row-gap: 3rem;
    padding: 0 3rem;
}

.recipe_tile {
    display: flex;
    flex-direction: column;
    margin: 15px;
    max-width: 250px;
    border-radius: 0 0 15px 15px;
    border: 1px solid #ccc;
}

.recipe_tile_details_link {
    cursor: pointer;
}

.recipe_tile_details_link:hover .recipe_tile_title {
    text-decoration: underline 4px coral;
    text-underline-position: under;
    text-underline-offset: .1em;
}

.recipe_tile_image {
    filter: contrast(85%);
    transition: 250ms ease;
}

.recipe_tile_details_link:hover .recipe_tile_image {
    filter: contrast(100%);
}


.recipe_tile_image .favorited_icon {
    color: coral;
    position: relative;
    top: -290px;
    left: 10px;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
    transition: 200ms ease;
}

.recipe_tile_image .favorited_icon:hover {
    font-size: 1.5rem;
}

.recipe_tile_image {
    width: 100%;
    height: 300px;
    display: block;
    object-fit: cover;
}

.recipe_tile_title {
    font-weight: bold;
    font-size: 1.5rem;
    padding: 1rem;
    font-family: 'Lora', serif;
}

.recipe_tile_description,
.recipe_tile_author {
    font-size: 1rem;
    min-height: fit-content;
    padding: 0 1rem;
    margin-top: 1rem;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

.recipe_tile_author {
    font-style: italic;
}

.recipe_tile_author > a {
    text-decoration: underline coral;
    text-underline-offset: .25rem;
    font-family: 'Lora', serif;;
    font-weight: bold;
}

.recipe_tile_author > a,
.recipe_tile_author > a:visited {
    color: black
}


.recipe_tile_author > a:hover {
    text-decoration-thickness: 4px;
    font-weight: bold;
}

.recipe_tile_tags {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    align-content: flex-end;
    justify-self: flex-end;
    flex-wrap: wrap;
    padding: 5px;
    height: 100%;
    margin-top: 1em;
}

.recipe_tile_tags > a {
    margin: .25em;
    padding: .5em;
    font-size: 75%;
    max-width: 95%;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    height: fit-content;
    width: fit-content;
    background-color: coral;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #fff;
    transition: 350ms ease;
    border-radius: .25em;
}

.recipe_tile_tags > a:hover {
    background-color: black;
}
