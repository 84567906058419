@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');

.tag_recipe_container {
    display: flex;
    flex-direction: column;
}

.recipe_page_tile {
    font-size: 3em;
    font-weight: bold;
    margin-left: 3em;
    margin-bottom: .5em;
    font-family: 'Lora', serif;
}
