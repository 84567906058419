.recipe_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 33%;
    min-width: 600px;
    margin: 0 auto;
}

.recipe_container  * {
    margin: 15px 0px;
    max-width: 100%;
}

.recipe_title_and_buttons_div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.single_recipe_title {
    font-size: 3rem;
    font-weight: bold;
    max-width: 75%;
    font-family: 'Lora', serif;
}

.recipe_details_buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: fit-content;
}

.recipe_details_buttons > * {
    border: none;
    font-size: 2rem;
    padding: .25rem;
    background-color: transparent;
    text-decoration: none;
    color: black;
    cursor: pointer;
    transition: 250ms ease;
}

.recipe_details_buttons #favorite_icon_empty,
.recipe_details_buttons #favorite_icon_solid {
    color: coral;
}

.recipe_details_buttons > *:hover {
    color: coral;
    font-size: 2.25rem;
}


.single_recipe_tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.single_recipe_tags > a {
    margin: .25em;
    padding: .5em;
    background-color: coral;
    border-radius: .25em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #fff;
    transition: 350ms ease;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

.single_recipe_tags > a:hover {
    background-color: black;
}


/* Recipe Image */
.single_recipe_image_div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.recipe_detail_image {
    height: 600px;
    width: 600px;
    object-fit: cover;
    border-radius: 1rem;
}

/************************ Recipe Details ****************************/

.recipe_details_div {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
}

.recipe_details_title_author_div,
.recipe_details_reviews_summary_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Lora', serif;
    max-width: 75%;
}

.recipe_num_reviews {
    margin-top: 0;
    font-size: 1.25rem;
}

.recipe_details_title {
    font-size: 1.75rem;
    font-weight: bold;
}

.recipe_details_author,
.recipe_details_estimated_time {
    font-size: 1.25rem;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;

}

.recipe_details_author > a,
.recipe_details_time {
    color: black;
    text-decoration: none;
    font-style: italic;
    font-weight: bold;
    transition: 250ms ease;
    font-family: 'Lora', serif;
}

.recipe_details_author > a:hover {
    color: coral;
}

.recipe_details_reviews_summary_div {
    font-size: 1.5rem;
    align-items: flex-end;
}

.recipe_avg_rating {
    margin: 0;
}

/***************** Description *******************/
.single_recipe_description {
    font-size: 1.25rem;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
}


.recipe_instruction_title,
.recipe_ingredients_title,
.review_title {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 'Lora', serif;
    text-decoration: underline;
}

.recipe_method_step_number,
.recipe_ingredient {
    font-size: 1.25rem;
    font-weight: bold;
    font-family: 'Lora', serif;
}

.recipe_method_details {
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 2rem;
}

.recipe_ingredient {
    list-style: none;
}

.ingredient_checkbox {
    margin-right: 1rem;
    cursor: pointer;
    accent-color: coral;
}



/************************** Steps ***************************/
.recipe_method_div {
    margin-left: 1rem;
}

.recipe_method_image_and_details_div {
    display: flex;
    flex-direction: row;
}

.recipe_method_image {
    max-height: 100%;
    width: 50%;
    border-radius: 1rem;
    margin-right: 1rem;
}

.recipe_method_details {
    font-size: 1.25rem;
    padding: 1rem;
}


/***************************** Reviews ******************************/
.reviews_div {
    display: flex;
    flex-direction: column;
    min-height: 25em;
}

.review_heading_div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0;
}

.review_average_rating,
.review_heading_left_div {
    font-size: 1.5rem;
}

.review_heading_right_div {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.review_heading_right_div *{
    margin: 0;
    padding: 0
}

.review_author_rating_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-width: fit-content;
}

.add_review_button_div {
    height: fit-content;
    margin: 0;
}

.add_review_button_div *{
    background-color: coral;
    border: none;
    border-radius: 1rem;
    color: white;
    width: fit-content;
    font-family: 'Lora', serif;
    padding: .75rem;
    cursor: pointer;
    transition: 250ms ease;
    margin-top: 0;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

.add_review_button_div *:hover {
    background-color: black;
}

/* Individual Review */
.review_wrapper * {
    word-break: break-all;
}

.review_wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid #999;
    border-radius: 1rem;
    padding: .5rem 1.5rem;
    background-color: #fef9f8;
}

.review_section,
.review_user_and_rating_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.25rem;
}

.review_timestamp,
.review_details {
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

.review_timestamp {
    font-size: 1rem;
}

.review_details {
    line-height: 1.5rem;
}

.review_username {
    margin-right: 5px;
    font-family: 'Lora', serif;
    font-weight: bold
}

.review_section {
    justify-content: space-between;
    align-items: center;
}

.review_section * {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
}

.star_rating,
.review_modal_buttons > *:hover {
    color: coral;
}

.review_modal_buttons_div *{
    background-color: transparent;
    border: none;
    font-size: 1.25rem;
    cursor: pointer;
    transition: 200ms ease;
    margin: 0 .15rem;
}

.review_modal_buttons_div *:hover {
    color: coral;
}

.review_section.review_top_div {
    margin-bottom: 0;
}

.review_section.review_modal_buttons_div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 0;
}

.review_section.review_details {
    margin-bottom: 0;
}
